import React from 'react';
import { Box, Container, Heading, Text, VStack, UnorderedList, ListItem } from '@chakra-ui/react';

function TermsAndConditions() {
  return (
    <Box bg="brand.background" minHeight="100vh" py={8}>
      <Container maxW="container.lg">
        <VStack spacing={6} align="stretch">
          <Heading color="brand.bodyFont" as="h1" size="2xl" textAlign="center" mb={6}>
            Terms and Conditions
          </Heading>

          <Text>
            Welcome to BlendNoids! By accessing or using BlendNoids (the "App"), you agree to comply with and be bound by the following terms and conditions ("Terms"). If you do not agree with any of these terms, you are prohibited from using or accessing this app.
          </Text>

          <Heading color="brand.bodyFont" as="h2" size="lg">1. Governing Law</Heading>
          <Text>
            These Terms are governed by and construed in accordance with the laws of the State of New York, without regard to its conflict of law provisions. By accessing or using the App, you agree to submit to the exclusive jurisdiction of the courts located in New York for any dispute that arises in connection with the App or these Terms.
          </Text>

          <Heading color="brand.bodyFont" as="h2" size="lg">2. Entertainment Purpose Only</Heading>
          <Text>
            The App is intended solely for entertainment purposes. The formulas, data, and any content provided on the App are not to be interpreted as medical advice, medical recommendations, or any form of healthcare guidance.
          </Text>
          <UnorderedList pl={4}>
          <ListItem>The formulas and ingredients generated or displayed by the App should not be used as a substitute for professional medical advice, diagnosis, or treatment. Always seek the advice of your physician or other qualified healthcare provider with any questions you may have regarding a medical condition or any effects of ingredients used.</ListItem>
          </UnorderedList>

          <Heading color="brand.bodyFont" as="h2" size="lg">3. No Medical Advice</Heading>
          <Text>
            The creators of the App are not licensed healthcare professionals, and the App does not offer or provide any form of medical advice. Any discussions, suggestions, or implied effects related to formulas, ingredients, or substances created through or by the use of the App are speculative and should not be relied upon as health or medical advice.
          </Text>
          <UnorderedList pl={4}>
          <ListItem>The use of any formula or substance discussed, displayed, or implied on the App is at your own risk. Users should consult their doctor or healthcare professional before using any formulas or substances created or referenced by the App.</ListItem>
          </UnorderedList>

          <Heading color="brand.bodyFont" as="h2" size="lg">4. User Responsibilities</Heading>
          <Text>You acknowledge that you are solely responsible for any decisions you make based on the information provided by the App. You agree that the App's creators and any associated parties are not liable for any damages or consequences that arise from your use of the App, including but not limited to, health complications, legal issues, or personal injury.</Text>
          <UnorderedList pl={4}>
            <ListItem>You agree not to use the App in any way that violates any applicable local, state, national, or international law, including but not limited to laws related to health, privacy, and consumer protection.</ListItem>
          </UnorderedList>

          <Heading color="brand.bodyFont" as="h2" size="lg">5. Limitation of Liability</Heading>
          <Text>
            To the maximum extent permitted by law, the App and its creators shall not be liable for any indirect, incidental, consequential, special, or punitive damages, including but not limited to, loss of profits, data, or other intangible losses, resulting from (a) your access to or use of, or inability to access or use the App; (b) any content obtained from the App; or (c) unauthorized access, use, or alteration of your transmissions or content.
          </Text>

          {/* Add more sections as needed */}

          <Heading color="brand.bodyFont" as="h2" size="lg">6. Intellectual Property</Heading>
          <Text>
            All content, designs, formulas, and materials provided on the App are the intellectual property of the App's creators or its licensors. You may not copy, reproduce, distribute, or create derivative works from any content on the App without explicit permission from the App's creators.
          </Text>

          <Heading color="brand.bodyFont" as="h2" size="lg">7. Modifications to the Terms</Heading>
          <Text>
            The App's creators reserve the right to revise these Terms at any time without notice. By continuing to use the App after any revisions, you agree to be bound by the updated terms.
          </Text>

          <Heading color="brand.bodyFont" as="h2" size="lg">8. Termination</Heading>
          <Text>
            We reserve the right to terminate or suspend your access to the App, without prior notice or liability, for any reason, including breach of these Terms.
          </Text>

          <Heading color="brand.bodyFont" as="h2" size="lg">9. Contact Us</Heading>
          <Text>
            If you have any questions about these Terms, please contact us at: alex@spensary.com
          </Text>

          <Text mt={6}>
            These Terms and Conditions were last updated on September 27th, 2024.
          </Text>
        </VStack>
      </Container>
    </Box>
  );
}

export default TermsAndConditions;
