import React, { useState } from 'react';
import { Box, Button, Input, VStack, Text } from "@chakra-ui/react";
import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from './firebase';

function PasswordResetForm() {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handlePasswordReset = async (email) => {
    try {
      await sendPasswordResetEmail(auth, email);
      alert("Password reset email sent. Check your inbox.");
    } catch (error) {
      console.error("Error sending password reset email:", error);
      alert(`Error: ${error.message}`);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await handlePasswordReset(email);
      setMessage("Password reset email sent. Check your inbox.");
    } catch (error) {
      setMessage(`Error: ${error.message}`);
    }
  };

  return (
    <Box maxW="md" mx="auto" as="form" onSubmit={handleSubmit}>
      <VStack spacing={4}>
        <Text>Enter your email to reset your password</Text>
        <Input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <Button type="submit" colorScheme="blue">Reset Password</Button>
        {message && <Text>{message}</Text>}
      </VStack>
    </Box>
  );
}

export default PasswordResetForm;
