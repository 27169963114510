import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Box, VStack, Heading, Text, Button, Link, Image } from '@chakra-ui/react';
import logo from './images/logo.png';

function Sidebar({ user, onLogout }) {
  return (
    <Box as="nav" bg="brand.sidebar" height="100%" width="100%" p={4}>
      <VStack spacing={4} align="stretch" height="100%">
        <Link as={RouterLink} to="/">
          <Image src={logo} alt="Blend Noids Logo" maxWidth="100%" height="auto" />
        </Link>
        <Link as={RouterLink} to="/">
          <Heading color="brand.sidebarFont" _hover={{ color: "brand.bodyFont" }} as="h3" size="sm" mb={0}>Blend Calculator</Heading>
        </Link>
        <Link as={RouterLink} to="/cartridge-calculator">
          <Heading color="brand.sidebarFont" _hover={{ color: "brand.bodyFont" }} as="h3" size="sm" mb={0}>Cartridge Calculator</Heading>
        </Link>
        <Link as={RouterLink} to="/terms-and-conditions">
          <Heading color="brand.sidebarFont" _hover={{ color: "brand.bodyFont" }} as="h3" size="sm" mb={0}>Terms and Conditions</Heading>
        </Link>


        <Box/>
          {user ? (
            <VStack spacing={2} align="stretch">
              <Text color="brand.sidebarFont">Welcome, {user.email}</Text>
              <Button onClick={onLogout} variant="outline" color="brand.sidebarFont" borderColor="brand.sidebarFont">
                Logout
              </Button>
            </VStack>
          ) : (
            <VStack spacing={2} align="stretch">
              <Link as={RouterLink} to="/login">
                <Button variant="outline" color="brand.sidebarFont" borderColor="brand.sidebarFont" width="100%">
                  Login
                </Button>
              </Link>
          
            </VStack>
          )}
        </VStack>
      </Box>
  );
}

export default Sidebar;
