import { drawerAnatomy as parts } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system'

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(parts.keys)

const baseStyle = definePartsStyle({
  // define the part you're going to style
  overlay: {
    bg: 'blackAlpha.800', //change the background
  },
  dialog: {
    borderRadius: 'md',
    bg: `#2A3243`,
  },
})

const green = definePartsStyle({
  dialog: {
    borderRadius: 'md',
    bg: '#2A3243',

    _dark: {
      bg: 'purple.600',
      color: 'white',
    },
  },
})

export const drawerTheme = defineMultiStyleConfig({
  variants: { green }
})
