import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCoFrFVE-a0Rao9qTKNJBSHSOc2DQmVIQg",
  authDomain: "blendcalc.firebaseapp.com",
  projectId: "blendcalc",
  storageBucket: "blendcalc.appspot.com",
  messagingSenderId: "981514464963",
  appId: "1:981514464963:web:81b3759dde3d22dce37c76",
  measurementId: "G-ZE6LCKGZ4Z"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

export { auth, db };
