// src/theme.js
import { extendTheme } from "@chakra-ui/react"
import { drawerTheme } from './components/theme/drawer'

const purpletheme = extendTheme({
  colors: {
    brand: {
      background: '#272D39',
      sidebar: "#2A3243",
      sidebarFont: "#FFFFFF",
      bodyFont: "#FFFFFF",
      tableHeader: "#1BB3A8"
    },
  },
  styles: {
    global: {
      body: {
        bg: "brand.background",
        color: "brand.bodyFont",
      },
      table: {
        bg: "brand.background",
        color: "brand.bodyFont",
      }
    },
  },
  components: {
    Button: {
      baseStyle: {
        _hover: {
          bg: "brand.sidebarFont",
        },
      },
    },
    Drawer: drawerTheme,
  },
})

export default purpletheme
