import React, { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import {
  Box,
  Heading,
  VStack,
  HStack,
  FormControl,
  FormLabel,
  Input,
  Button,
  Alert,
  AlertIcon,
  Text,
  Link
} from '@chakra-ui/react';
import { signInWithEmailAndPassword, createUserWithEmailAndPassword, sendPasswordResetEmail } from 'firebase/auth';
import { auth } from './firebase';  // Adjust this import path as necessary
import PasswordResetForm from './passwordReset';


function LoginPage() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleLogin = async () => {
    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate('/');
    } catch (error) {
      setError(error.message);
    }
  };

  const handleSignUp = async () => {
    try {
      await createUserWithEmailAndPassword(auth, email, password);
      navigate('/');
    } catch (error) {
      setError(error.message);
    }
  };

  const handlePasswordReset = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email);
    alert("Password reset email sent. Check your inbox.");
  } catch (error) {
    console.error("Error sending password reset email:", error);
    alert("Error sending password reset email. Please try again.");
  }
  };

  const handleForgotPassword = () => {
    if (email) {
      handlePasswordReset(email);
    } else {
      alert("Please enter your email address first.");
    }
  };

  return (
    <Box maxW="md" mx="auto">
      <Heading color="brand.bodyFont" as="h2" size="xl" textAlign="center" mb={6}>Login or Sign Up</Heading>
      <Heading color="brand.bodyFont" as="h2" size="md" textAlign="center" mb={6}>Create an account to save and share your formulas.</Heading>
      <VStack spacing={4} align="stretch">
        <FormControl>
          <FormLabel>Email address</FormLabel>
          <Input
            type="email"
            placeholder="Enter email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </FormControl>
        <FormControl>
          <FormLabel>Password</FormLabel>
          <Input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </FormControl>
        <HStack>
          <Button colorScheme="blue" onClick={handleLogin}>
            Login
          </Button>
          <Button onClick={handleSignUp}>
            Sign Up
          </Button>
          <Text>
            <Link as={RouterLink} to="/password-reset">Forgot Password?</Link>
          </Text>
        </HStack>
        {error && (
          <Alert status="error">
            <AlertIcon />
            {error}
          </Alert>
        )}
      </VStack>
    </Box>
  );
}

export default LoginPage;
