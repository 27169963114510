import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Routes, Route, Link, useNavigate, useLocation } from 'react-router-dom';
import {
  Box, Heading, Container, Flex, Grid, Button, Input, FormControl, FormLabel,
  Table, Thead, Tbody, Tr, Th, Td, Checkbox, Text, VStack, HStack, Spacer,
  Card, CardBody, CardHeader, Alert, AlertIcon, NumberInput, NumberInputField, Tfoot, useDisclosure, Drawer, DrawerBody, DrawerHeader, DrawerOverlay, DrawerContent, DrawerCloseButton,
  IconButton
} from '@chakra-ui/react';
import { HamburgerIcon, DeleteIcon } from '@chakra-ui/icons';
import { auth, db } from './firebase';
import './App.css';
import './index.css';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut, onAuthStateChanged } from 'firebase/auth';
import { collection, getDocs, doc, setDoc, deleteDoc, orderBy, query, updateDoc, getDoc } from 'firebase/firestore';
import Sidebar from './Sidebar';
import FormulaPage from './formulaPage';
import CartridgeCalculator from './cartridgeCalculator';
import CartridgePage from './cartridgePage';
import LoginPage from './login';
import TermsAndConditions from './terms';
import PasswordResetForm from './passwordReset';
import { groupBy } from 'lodash';  // or implement your own groupBy function


function App() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const handleLogout = () => {
    signOut(auth).then(() => {
      // Sign-out successful.
    }).catch((error) => {
      console.error("Logout error:", error);
    });
  };

  if (loading) {
    return <Box>Loading...</Box>;
  }

  return (
    <Box width="100%" height="100vh" bg="brand.background">
      <Flex direction={{ base: "column", md: "row" }} minHeight="100vh">
        <Box display={{ base: "block", md: "none" }} p={4}>
          <IconButton
            icon={<HamburgerIcon />}
            onClick={onOpen}
            aria-label="Open Menu"
          />
        </Box>
        <Drawer variant='green' isOpen={isOpen} placement="left" onClose={onClose}>
          <DrawerOverlay>
            <DrawerContent>
              <DrawerCloseButton />
              <DrawerHeader color="brand.sidebarFont">Menu</DrawerHeader>
              <DrawerBody>
                <Sidebar user={user} onLogout={handleLogout} />
              </DrawerBody>
            </DrawerContent>
          </DrawerOverlay>
        </Drawer>
        <Box display={{ base: "none", md: "block" }} w="200px" bg="brand.sidebar">
          <Sidebar user={user} onLogout={handleLogout} />
        </Box>
        <Box flex={1} overflowY="auto" p={4} color="brand.bodyFont">
          <Routes>
            <Route path="/" element={<Dashboard user={user} />} />
            <Route path="/formula/:id" element={<FormulaPage user={user} />} />
            <Route path="/cartridge-calculator" element={<CartridgeCalculator user={user} />} />
            <Route path="/cartridge/:id" element={<CartridgePage user={user} />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
            <Route path="/password-reset" element={<PasswordResetForm />} />
          </Routes>
        </Box>
      </Flex>
    </Box>
  );
}

const getBackgroundColor = (effect) => {
  const colorMap = {
    'Relaxing': 'blue.600',
    'Stimulating': 'green.600',
    'Head High': 'purple.600',
    'Potentiation': 'red.600',
    'Sedation': 'blue.700',
    'Euphoric': 'orange.600',
    'Neutral': 'green.600',
    'Couch Lock': 'gray.600',
    'Flavor': 'blue.500',
    'Custom': 'purple.400'
    // Add more effects and colors as needed
  };
  return colorMap[effect] || 'gray.100';
};

function Dashboard({ user }) {
  const [error, setError] = useState('');
  const [ratios, setRatios] = useState({});
  const [formulaName, setFormulaName] = useState('');
  const [batchSize, setBatchSize] = useState(0);
  const [availableIngredients, setAvailableIngredients] = useState([]);
  const [formulaData, setFormulaData] = useState([]);
  const [isLoadingFormula, setIsLoadingFormula] = useState(false);
  const [savedFormulas, setSavedFormulas] = useState([]);
  const navigate = useNavigate();
  const [isPublic, setIsPublic] = useState(false);
  const [customIngredients, setCustomIngredients] = useState([]);
  const [newIngredientName, setNewIngredientName] = useState('');
  const [nextCustomId, setNextCustomId] = useState(1000);
  const effectGroups = {
    ...groupBy(availableIngredients.filter(ing => ing.id && (typeof ing.id !== 'string' || !ing.id.startsWith('custom_'))), 'effect'),
    Custom: customIngredients
  };
  const [customPrices, setCustomPrices] = useState({});
  const [editingFormulaId, setEditingFormulaId] = useState(null);
  const location = useLocation();



  const allIngredients = useMemo(() => {
    const standardIngredients = availableIngredients.filter(ing => !ing.isCustom);
    const uniqueIngredients = [...standardIngredients, ...customIngredients];
    console.log('All ingredients:', uniqueIngredients);
    return uniqueIngredients;
  }, [availableIngredients, customIngredients]);

  const groupedIngredients = useMemo(() => {
    const grouped = groupBy(allIngredients, ing => ing.isCustom ? 'Custom' : ing.effect);
    console.log('Grouped ingredients:', grouped);
    return grouped;
  }, [allIngredients]);

  const sortedEffects = useMemo(() => {
    return Object.keys(groupedIngredients).sort((a, b) => {
      if (a === 'Custom') return 1;
      if (b === 'Custom') return -1;
      return a.localeCompare(b);
    });
  }, [groupedIngredients]);


  const loadFormulaForEditing = useCallback(async (formulaId) => {
    setIsLoadingFormula(true);
    let formula = savedFormulas.find(f => f.id === formulaId);

    if (!formula && user) {
      try {
        const formulaRef = doc(db, 'users', user.uid, 'formulas', formulaId);
        const formulaDoc = await getDoc(formulaRef);
        if (formulaDoc.exists()) {
          formula = { id: formulaDoc.id, ...formulaDoc.data() };
        }
      } catch (error) {
        console.error("Error fetching formula:", error);
      }
    }

    if (formula) {
      setEditingFormulaId(formulaId);
      setFormulaName(formula.name);
      setBatchSize(formula.batchSize);

      const newRatios = {};
      const newCustomPrices = {};
      const formulaCustomIngredients = formula.ingredients.filter(ing => ing.isCustom);

      formula.ingredients.forEach(ing => {
        newRatios[ing.id] = ing.ratio;
        newCustomPrices[ing.id] = ing.costPerGram || 0;
      });

      setRatios(newRatios);
      setCustomPrices(newCustomPrices);

      // Update custom ingredients
      setCustomIngredients(formulaCustomIngredients.map(ing => ({...ing, effect: 'Custom'})));

      // Update available ingredients
      setAvailableIngredients(prevAvailableIngs => {
        const standardIngs = prevAvailableIngs.filter(ing => !ing.isCustom);
        return [...standardIngs, ...formulaCustomIngredients];
      });

      // Scroll to top of the page
      window.scrollTo(0, 0);
    }
    setIsLoadingFormula(false);
  }, [savedFormulas, user]);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const editId = queryParams.get('edit');
    if (editId && user && savedFormulas.length > 0 && !isLoadingFormula) {
      loadFormulaForEditing(editId);
    }
  }, [window.location.search, user, savedFormulas, loadFormulaForEditing, isLoadingFormula]);

  useEffect(() => {
    const state = location.state;
    if (state && state.editFormulaId && !isLoadingFormula) {
      setIsLoadingFormula(true);
      if (state.formulaData) {
        const formulaData = state.formulaData;
        setEditingFormulaId(state.editFormulaId);
        setFormulaName(formulaData.name);
        setBatchSize(formulaData.batchSize);

        const newRatios = {};
        const newCustomPrices = {};
        const customIngs = formulaData.ingredients.filter(ing => ing.isCustom);

        formulaData.ingredients.forEach(ing => {
          newRatios[ing.id] = ing.ratio;
          newCustomPrices[ing.id] = ing.costPerGram || 0;
        });

        setRatios(newRatios);
        setCustomPrices(newCustomPrices);

        // Update custom ingredients
        setCustomIngredients(prevCustomIngs => {
          const updatedCustomIngs = [...prevCustomIngs];
          customIngs.forEach(customIng => {
            if (!updatedCustomIngs.some(ing => ing.id === customIng.id)) {
              updatedCustomIngs.push({...customIng, effect: 'Custom'});
            }
          });
          return updatedCustomIngs;
        });

        // Update available ingredients to include custom ingredients
        setAvailableIngredients(prevAvailableIngs => {
          const standardIngs = prevAvailableIngs.filter(ing => !ing.isCustom);
          return [...standardIngs, ...customIngs];
        });

        console.log('Custom ingredients after setting:', customIngs);
      } else {
        loadFormulaForEditing(state.editFormulaId);
      }
      navigate('/', { replace: true, state: {} });
      setIsLoadingFormula(false);
    }
  }, [location, loadFormulaForEditing, navigate, isLoadingFormula]);


  useEffect(() => {
    const fetchIngredients = async () => {
      try {
        // Fetch standard ingredients
        const ingredientsQuery = query(collection(db, 'ingredients'), orderBy('id', 'asc'));
        const querySnapshot = await getDocs(ingredientsQuery);
        const ingredientList = querySnapshot.docs.map(doc => ({
          id: doc.data().id,
          name: doc.data().name,
          effect: doc.data().effect,
          prices: doc.data().prices,
          isCustom: false
        }));

        // Fetch custom ingredients if user is logged in
        let customList = [];
        if (user) {
          const customIngredientsQuery = query(collection(db, 'users', user.uid, 'customIngredients'));
          const customSnapshot = await getDocs(customIngredientsQuery);
          customList = customSnapshot.docs.map(doc => ({
            id: doc.id,
            name: doc.data().name,
            effect: 'Custom',
            prices: doc.data().prices || {1: 0},
            isCustom: true
          }));
        }

        // Combine standard and custom ingredients
        setAvailableIngredients(prevIngredients => {
          const existingCustomIngredients = prevIngredients.filter(ing => ing.isCustom);
          return [...ingredientList, ...existingCustomIngredients, ...customList];
        });
        setCustomIngredients(prevCustomIngredients => {
          const newCustomIngredients = customList.filter(newIng =>
            !prevCustomIngredients.some(prevIng => prevIng.id === newIng.id)
          );
          return [...prevCustomIngredients, ...newCustomIngredients];
        });

        // Set nextCustomId
        setNextCustomId(Math.max(...customList.map(ing => parseInt(ing.id.split('_')[1])), 999) + 1);

      } catch (error) {
        console.error("Error fetching ingredients: ", error);
      }
    };

    fetchIngredients();
  }, [user]);



  useEffect(() => {
  const fetchSavedFormulas = async () => {
    if (!user) return;

    try {
      const userFormulasRef = collection(db, 'users', user.uid, 'formulas');
      const formulasSnapshot = await getDocs(userFormulasRef);

      const userFormulas = formulasSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));

      setSavedFormulas(userFormulas);
    } catch (error) {
      console.error('Error fetching saved formulas:', error);
    }
  };

  fetchSavedFormulas();
}, [user]);



  const handleDeleteCustomIngredient = (ingredientId) => {
    const isCustom = typeof ingredientId === 'string' && ingredientId.startsWith('custom_');

    if (isCustom) {
      // Remove from customIngredients
      setCustomIngredients(prevIngredients =>
        prevIngredients.filter(ing => ing.id !== ingredientId)
      );

      // Remove from availableIngredients
      setAvailableIngredients(prevIngredients =>
        prevIngredients.filter(ing => ing.id !== ingredientId)
      );

      // Remove from ratios
      setRatios(prevRatios => {
        const { [ingredientId]: _, ...newRatios } = prevRatios;
        return newRatios;
      });

      // Remove from customPrices
      setCustomPrices(prevPrices => {
        const { [ingredientId]: _, ...newPrices } = prevPrices;
        return newPrices;
      });

      // Remove from formulaData if it exists
      setFormulaData(prevData =>
        prevData.filter(item => item.id !== ingredientId)
      );
    }
  };

  const handleRatioChange = (ingredientId, value) => {
    const ratio = parseFloat(value) || 0;
    const quantity = calculateQuantity(ingredientId);

    setRatios(prevRatios => ({
      ...prevRatios,
      [ingredientId]: ratio
    }));

    setFormulaData((prevState) => {
      const updatedData = prevState.filter(item => item.id !== ingredientId);
      const ingredient = allIngredients.find(ing => ing.id === ingredientId);
      if (ingredient) {
        updatedData.push({
          id: ingredientId,
          name: ingredient.name,
          ratio: ratio,
          quantity: quantity,
          isCustom: ingredient.isCustom
        });
      }
      return updatedData;
    });

    console.log(`Ratio changed for ingredient ${ingredientId}: ${ratio}`);
  };

  const handleAddCustomIngredient = () => {
    if (newIngredientName.trim() !== '') {
      const existingIngredient = allIngredients.find(
        ing => ing.name.toLowerCase() === newIngredientName.trim().toLowerCase()
      );

      if (existingIngredient) {
        setRatios(prevRatios => ({
          ...prevRatios,
          [existingIngredient.id]: prevRatios[existingIngredient.id] || 0
        }));
      } else {
        const newIngredientId = `custom_${Date.now()}_${nextCustomId}`;
        const newIngredient = {
          id: newIngredientId,
          name: newIngredientName.trim(),
          effect: 'Custom',
          prices: {1: 0},
          isCustom: true
        };
        setCustomIngredients(prevIngredients => [...prevIngredients, newIngredient]);
        setAvailableIngredients(prevIngredients => [...prevIngredients, newIngredient]);
        setRatios(prevRatios => ({
          ...prevRatios,
          [newIngredientId]: 0
        }));
        setNextCustomId(prevId => prevId + 1);
      }
      setNewIngredientName('');
    }
  };

  // Helper function to get the price based on quantity
  const priceBreaks = [1000, 500, 100, 50, 10, 1];  // Pre-reversed

  const getPriceForQuantity = (ingredient, quantity) => {
    if (!ingredient || !ingredient.prices) {
      console.error('Invalid ingredient data:', ingredient);
      return 0; // Return a default price or handle this case as appropriate
    }
    const applicableBreak = priceBreaks.find(priceBreak => quantity >= priceBreak) || 1;
    return ingredient.prices[applicableBreak] || 0;
  };

  const handleCustomPriceChange = (ingredientId, value) => {
    setCustomPrices(prevPrices => ({
      ...prevPrices,
      [ingredientId]: value
    }));
  };

  // Calculate price for an ingredient
  const calculatePrice = (ingredient, ratio, useCustomPrice = false) => {
    if (!ingredient || typeof ingredient.id === 'undefined') {
      console.error('Invalid ingredient:', ingredient);
      return '0.00';
    }
    const quantity = (ratio / 100) * batchSize;
    const price = useCustomPrice
      ? parseFloat(customPrices[ingredient.id]) || 0
      : getPriceForQuantity(ingredient, quantity);
    return (quantity * price).toFixed(2);
  };


  const handleDeleteFormula = async (formulaId) => {
    try {
      await deleteDoc(doc(db, 'users', user.uid, 'formulas', formulaId));
      setSavedFormulas((prevFormulas) => prevFormulas.filter(formula => formula.id !== formulaId));
      console.log("Formula deleted successfully!");
    } catch (error) {
      console.error("Error deleting formula: ", error);
    }
  };

  const handleSaveFormula = async (isPublic = false) => {
    if (!user) {
      setError('Please log in to save formulas.');
      return;
    }

    // Create a Map to store unique ingredients by name
    const uniqueIngredients = new Map();

    [...availableIngredients, ...customIngredients].forEach(ingredient => {
      if (ingredient && ingredient.id && ratios[ingredient.id] > 0) {
        uniqueIngredients.set(ingredient.name, {
          id: ingredient.id,
          name: ingredient.name,
          ratio: ratios[ingredient.id] || 0,
          quantity: calculateQuantity(ingredient.id),
          costPerGram: customPrices[ingredient.id] || 0
        });
      }
    });

    const formula = {
      name: formulaName,
      ingredients: Array.from(uniqueIngredients.values()).map(ing => ({
        ...ing,
        isCustom: typeof ing.id === 'string' && ing.id.startsWith('custom_')
      })),
      batchSize,
      createdBy: user.uid,
    };

    try {
      let formulaRef;

      if (editingFormulaId) {
        formulaRef = doc(db, 'users', user.uid, 'formulas', editingFormulaId);
        await updateDoc(formulaRef, formula);
      } else {
        if (isPublic) {
          formulaRef = doc(collection(db, 'formulas'));
        } else {
          formulaRef = doc(collection(db, 'users', user.uid, 'formulas'));
        }
        await setDoc(formulaRef, formula);
      }

      setSavedFormulas((prevFormulas) => {
        if (editingFormulaId) {
          return prevFormulas.map(f => f.id === editingFormulaId ? { ...formula, id: editingFormulaId } : f);
        } else {
          return [...prevFormulas, { ...formula, id: formulaRef.id }];
        }
      });

      console.log(`Formula ${editingFormulaId ? 'updated' : 'saved'} successfully!`);
      setEditingFormulaId(null);
      navigate(`/formula/${formulaRef.id}`);
    } catch (error) {
      console.error("Error saving formula: ", error);
    }
  };

  const calculateTotals = () => {
    let totalRatio = 0;
    let totalQuantity = 0;
    let totalPrice = 0;

    // Create a Set of unique ingredient IDs
    const uniqueIngredientIds = new Set([
      ...availableIngredients.map(ing => ing.id),
      ...customIngredients.map(ing => ing.id)
    ]);

    // Iterate over unique ingredients
    uniqueIngredientIds.forEach((id) => {
      const ingredient = availableIngredients.find(ing => ing.id === id) ||
                         customIngredients.find(ing => ing.id === id);
      if (ingredient) {
        const ratio = parseFloat(ratios[ingredient.id]) || 0;
        const quantity = (ratio / 100) * batchSize;
        const price = parseFloat(calculatePrice(ingredient, ratio, true));
        totalRatio += ratio;
        totalQuantity += quantity;
        totalPrice += price;
      }
    });

    return { totalRatio, totalQuantity, totalPrice };
  };

  const calculateQuantity = (ingredientId) => {
    if (typeof ingredientId !== 'string' && typeof ingredientId !== 'number') return '0.00';
    const ratio = ratios[ingredientId] || 0;
    return ((ratio / 100) * batchSize).toFixed(3);  // Changed to 3 decimal places for more precision
  };




  const { totalRatio, totalQuantity, totalPrice } = calculateTotals();
  useEffect(() => {
    console.log('Available ingredients:', availableIngredients);
    console.log('Custom ingredients:', customIngredients);
  }, [availableIngredients, customIngredients]);

  useEffect(() => {
    console.log('All ingredients updated:', allIngredients);
  }, [allIngredients]);

  // Add this right before the return statement in the Dashboard component
  console.log('Grouped ingredients:', groupedIngredients);


  return (
    <Box p={0}>
      <Heading as="h2" size="md" mb={2} pl={0} pt={1} color="brand.bodyFont">Cannabinoid Blend Calculator Dashboard</Heading>

      <VStack spacing={6} align="stretch">
        {/* Formula Input Section */}
        <Box overflowX="auto"> {/* Add this wrapper for horizontal scrolling on small screens */}

          <HStack spacing={4} align="flex-end" mb={2}>
            <FormControl flex={1}>
              <FormLabel htmlFor="formula-name" color="brand.bodyFont">Formula Name</FormLabel>
              <Input
                id="formula-name"
                size='sm'
                color="brand.bodyFont"
                value={formulaName}
                onChange={(e) => setFormulaName(e.target.value)}
                placeholder="Enter formula name"

              />
            </FormControl>
            <FormControl flex={1}>
              <FormLabel htmlFor="batch-size">Batch Size (grams) *Required*</FormLabel>
              <NumberInput
                id="batch-size"
                size='sm'
                value={batchSize}
                onChange={(valueString) => setBatchSize(parseFloat(valueString))}
              >
                <NumberInputField color="brand.bodyFont" placeholder="Enter batch size" />
              </NumberInput>
            </FormControl>
          </HStack>

          <Box mb={4}>
            <Heading as="h4" size="sm" mb={2} color="brand.bodyFont">Add Custom Ingredient</Heading>
            <Flex direction={{ base: "column", md: "row" }} align="stretch">
              <Input
                id="add-ingredient"
                value={newIngredientName}
                size='sm'
                mb={{ base: 2, md: 0 }}
                mr={{ md: 2 }}
                onChange={(e) => setNewIngredientName(e.target.value)}
                placeholder="Enter custom ingredient name"
              />
              <Button
                onClick={handleAddCustomIngredient}
                colorScheme="blue"
                width={{ base: "full", md: "auto" }}
                flexShrink={0}
                >
                Add Ingredient
              </Button>
            </Flex>
          </Box>

          <Table size='sm' variant="unstyled" width="100%" colorScheme='whiteAlpha' tableLayout="fixed">
            <Thead>
              <Tr>
                <Th color="brand.tableHeader" borderColor="gray.600" borderWidth="0.25px">Ingredient Name</Th>
                <Th color="brand.tableHeader" borderColor="gray.600" borderWidth="0.25px">Ratio (%)</Th>
                <Th color="brand.tableHeader" borderColor="gray.600" borderWidth="0.25px">{batchSize > 0 ? `Quantity for ${batchSize}g batch` : 'Quantity'}</Th>
                <Th color="brand.tableHeader" borderColor="gray.600" borderWidth="0.25px">Custom Price ($/g)</Th>
                <Th color="brand.tableHeader" borderColor="gray.600" borderWidth="0.25px">Custom Price Total ($)</Th>
                {/*}<Th color="brand.tableHeader" borderColor="gray.600" borderWidth="0.25px">Price ($) (Bona Voluntate)</Th>*/}

              </Tr>
            </Thead>
            <Tbody>
              {sortedEffects.map((effect) => (
                <React.Fragment key={effect || 'undefined'}>
                  <Tr bg={getBackgroundColor(effect)}>
                    <Td colSpan={5} fontWeight="bold">Type: {effect || 'Other'}</Td>
                  </Tr>
                    {groupedIngredients[effect] && groupedIngredients[effect].map((ingredient) => (
                    <Tr key={ingredient.id}>
                      <Td borderColor="gray.600" borderWidth="0.25px">
                        {ingredient.name}
                        {ingredient.isCustom && (
                          <IconButton
                            aria-label="Delete custom ingredient"
                            icon={<DeleteIcon />}
                            size="xs"
                            colorScheme="red"
                            ml={2}
                            onClick={() => handleDeleteCustomIngredient(ingredient.id)}
                          />
                        )}
                      </Td>
                      <Td verticalAlign="middle" borderColor="gray.600" borderWidth="0.25px" height="40px">
                        <Flex align="center" height="100%">
                          <NumberInput
                            size='xs'
                            value={ratios[ingredient.id] || 0}
                            onChange={(valueString) => handleRatioChange(ingredient.id, valueString)}
                            min={0}
                            max={100}
                            width="100%"
                          >
                            <NumberInputField />
                          </NumberInput>
                        </Flex>
                      </Td>
                      <Td borderColor="gray.600" borderWidth="0.25px">{calculateQuantity(ingredient.id)}g</Td>
                      <Td verticalAlign="middle" borderColor="gray.600" borderWidth="0.25px" height="40px">
                        <Flex align="center" height="100%">
                          <NumberInput
                            size='xs'
                            value={customPrices[ingredient.id] || ''}
                            onChange={(valueString) => handleCustomPriceChange(ingredient.id, valueString)}
                            min={0}
                            precision={2}
                            step={0.01}
                            width="100%"
                          >
                            <NumberInputField />
                          </NumberInput>
                        </Flex>
                      </Td>
                      <Td borderColor="gray.600" borderWidth="0.25px">
                        ${calculatePrice(ingredient, ratios[ingredient.id] || 0, true)}
                      </Td>
                      {/*<Td borderColor="gray.600" borderWidth="0.25px">${calculatePrice(ingredient, ratios[ingredient.id] || 0)}</Td>*/}
                    </Tr>
                  ))}
                </React.Fragment>
              ))}
            </Tbody>




            <Tfoot>
              <Tr fontWeight="bold" colorScheme='whiteAlpha' bg="gray.800">
                <Th textAlign="left" borderColor="gray.600" borderWidth="0.25px" color='white'>Total</Th>
                <Th textAlign="left" borderColor="gray.600" borderWidth="0.25px" color={totalRatio !== 100 ? 'red.500' : 'white'}>
                  {totalRatio.toFixed(2)}%
                </Th>
                <Th textAlign="left" borderColor="gray.600" borderWidth="0.25px" color={totalQuantity !== parseFloat(batchSize) ? 'red.500' : 'white'}>
                  {totalQuantity.toFixed(2)}g
                </Th>
                <Th textAlign="left" borderColor="gray.600" borderWidth="0.25px" color='white'>-</Th>
                <Th isNumeric color='white' textAlign="left" borderColor="gray.600" borderWidth="0.25px">
                  ${[...new Set([...availableIngredients, ...customIngredients])].reduce((sum, ingredient) =>
                    ingredient && ingredient.id
                      ? sum + parseFloat(calculatePrice(ingredient, ratios[ingredient.id] || 0, true))
                      : sum,
                    0
                  ).toFixed(2)}
                </Th>
                {/*}<Th isNumeric color='white' textAlign="left" borderColor="gray.600" borderWidth="0.25px">${totalPrice.toFixed(2)}</Th>*/}
              </Tr>
            </Tfoot>
          </Table>

          <HStack mt={4}>
            <Button colorScheme="blue" onClick={() => handleSaveFormula(isPublic)}>
              {editingFormulaId ? 'Update Formula' : 'Save Formula'}
            </Button>
            {editingFormulaId && (
              <Button colorScheme="gray" onClick={() => {
                setEditingFormulaId(null);
                setFormulaName('');
                setBatchSize(0);
                setRatios({});
                setCustomPrices({});
              }}>
                Cancel Edit
              </Button>
            )}
            <Checkbox
              isChecked={isPublic}
              onChange={(e) => setIsPublic(e.target.checked)}
            >
              Make Public
            </Checkbox>
          </HStack>
        </Box>

        {/* Saved Formulas Section */}
        {savedFormulas.length > 0 && (
          <Box>
            <Heading color="brand.bodyFont" as="h3" size="lg" mb={4}>Saved Formulas</Heading>
            <VStack spacing={4} align="stretch">
              {savedFormulas.map((formula, index) => (
                <Box key={index} borderWidth={1} borderRadius="lg" p={4}>
                  <Heading as="h4" size="md" color="brand.bodyFont">
                    <Link to={`/formula/${formula.id}`}>{formula.name} (Batch Size: {formula.batchSize}g)</Link>
                  </Heading>
                    <HStack mt={2}>
                        <Button colorScheme="blue" size="sm" onClick={() => loadFormulaForEditing(formula.id)}>
                          Edit
                        </Button>
                        <Button colorScheme="red" size="sm" onClick={() => handleDeleteFormula(formula.id)}>
                          Delete
                        </Button>
                    </HStack>
                  <Table variant="simple" size="sm" mt={2}>
                    <Thead>
                      <Tr>
                        <Th color="brand.tableHeader">Ingredient Name</Th>
                        <Th color="brand.tableHeader">Ratio (%)</Th>
                        <Th color="brand.tableHeader">Quantity</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {formula.ingredients.map((ingredient, idx) => (
                        <Tr key={idx}>
                          <Td>{ingredient.name}</Td>
                          <Td>{ingredient.ratio}%</Td>
                          <Td>{ingredient.quantity}g</Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                </Box>
              ))}
            </VStack>
          </Box>
        )}
      </VStack>
    </Box>
  );
}

// function LoginForm() {
//   const [email, setEmail] = useState('');
//   const [password, setPassword] = useState('');
//   const [error, setError] = useState('');
//   const navigate = useNavigate();
//
//   const handleLogin = async () => {
//     try {
//       await signInWithEmailAndPassword(auth, email, password);
//       navigate('/');
//     } catch (error) {
//       setError(error.message);
//     }
//   };
//
//   const handleSignUp = async () => {
//     try {
//       await createUserWithEmailAndPassword(auth, email, password);
//       navigate('/');
//     } catch (error) {
//       setError(error.message);
//     }
//   };
//
//   return (
//     <Box maxW="md" mx="auto">
//       <Heading color="brand.bodyFont" as="h2" size="xl" textAlign="center" mb={6}>Login or Sign Up</Heading>
//       <Heading color="brand.bodyFont" as="h2" size="md" textAlign="center" mb={6}>Create an account to save and share your formulas.</Heading>
//       <VStack spacing={4} align="stretch">
//         <FormControl>
//           <FormLabel>Email address</FormLabel>
//           <Input
//             type="email"
//             placeholder="Enter email"
//             value={email}
//             onChange={(e) => setEmail(e.target.value)}
//           />
//         </FormControl>
//         <FormControl>
//           <FormLabel>Password</FormLabel>
//           <Input
//             type="password"
//             placeholder="Password"
//             value={password}
//             onChange={(e) => setPassword(e.target.value)}
//           />
//         </FormControl>
//         <HStack>
//           <Button colorScheme="blue" onClick={handleLogin}>
//             Login
//           </Button>
//           <Button onClick={handleSignUp}>
//             Sign Up
//           </Button>
//         </HStack>
//         {error && (
//           <Alert status="error">
//             <AlertIcon />
//             {error}
//           </Alert>
//         )}
//       </VStack>
//     </Box>
//   );
// }

export default App;
