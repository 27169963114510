import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db, auth } from './firebase';
import {
  Container,
  Button,
  VStack,
  Heading,
  Text,
  Input,
  Textarea,
  NumberInput,
  NumberInputField,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  Switch,
  FormControl,
  FormLabel,
  HStack,
  Box,
  Alert,
  AlertIcon
} from '@chakra-ui/react';

function FormulaPage() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [formula, setFormula] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isPublic, setIsPublic] = useState(false);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [batchSize, setBatchSize] = useState(0);
  const [isEditing, setIsEditing] = useState(false);
  const [error, setError] = useState(null);
  const [formulaPath, setFormulaPath] = useState(null);
  const [customIngredients, setCustomIngredients] = useState([]);

  useEffect(() => {
  const fetchFormula = async () => {
    try {
      const user = auth.currentUser;
      if (!user) {
        console.error("No user logged in");
        setError("You must be logged in to view this formula.");
        setLoading(false);
        return;
      }

      console.log("Fetching formula with ID:", id);

      // Try private formula first
      let formulaRef = doc(db, 'users', user.uid, 'formulas', id);
      let formulaDoc = await getDoc(formulaRef);

      // If not found, try public formula
      if (!formulaDoc.exists()) {
        formulaRef = doc(db, 'formulas', id);
        formulaDoc = await getDoc(formulaRef);
      }

      if (formulaDoc.exists()) {
        const formulaData = formulaDoc.data();
        console.log("Formula found:", formulaData);
        setFormula(formulaData);
        setIsPublic(formulaRef.path.startsWith('formulas'));
        setTitle(formulaData.name || '');
        setDescription(formulaData.description || '');
        setBatchSize(formulaData.batchSize || 0);
        setFormulaPath(formulaRef.path);

        // Set custom ingredients
        const customIngs = formulaData.ingredients.filter(ing => ing.isCustom);
        setCustomIngredients(customIngs);
      } else {
        console.log("No such formula!");
        setError("Formula not found.");
        setFormula(null);
      }
    } catch (error) {
      console.error("Error fetching formula: ", error);
      setError("An error occurred while fetching the formula.");
    }
    setLoading(false);
  };
  fetchFormula();
}, [id]);

const handleEdit = () => {
  navigate('/', {
    state: {
      editFormulaId: id,
      formulaData: {
        ...formula,
        ingredients: formula.ingredients.map(ing => ({
          ...ing,
          effect: ing.isCustom ? 'Custom' : ing.effect,
          isCustom: !!ing.isCustom  // Ensure isCustom is a boolean
        }))
      }
    }
  });
};

const handleSave = async () => {
  try {
    const user = auth.currentUser;
    if (!user) {
      console.error("No user logged in");
      setError("You must be logged in to update this formula.");
      return;
    }

    if (!formulaPath) {
      console.error("Formula path is not set");
      setError("Unable to update formula: path not found.");
      return;
    }

    console.log("Updating formula at path:", formulaPath);

    const formulaRef = doc(db, formulaPath);
    const formulaDoc = await getDoc(formulaRef);

    if (!formulaDoc.exists()) {
      console.error("Formula not found at path:", formulaPath);
      setError("Formula not found. Unable to update.");
      return;
    }

    const currentFormulaData = formulaDoc.data();
    console.log("Current formula data:", currentFormulaData);

    const newBatchSize = parseFloat(batchSize);
    const updatedIngredients = currentFormulaData.ingredients.map(ing => ({
      ...ing,
      quantity: ((ing.ratio / 100) * newBatchSize).toFixed(2)
    }));

    const updatedFormula = {
      ...currentFormulaData,
      name: title,
      description: description,
      batchSize: newBatchSize,
      ingredients: updatedIngredients
    };

    console.log("Updating formula with data:", updatedFormula);

    await updateDoc(formulaRef, updatedFormula);

    setFormula(updatedFormula);
    setIsEditing(false);
    setError(null);
    console.log("Formula updated successfully");
  } catch (error) {
    console.error("Error updating formula: ", error);
    setError("An error occurred while updating the formula.");
  }
};

if (loading) {
  return (
    <Container maxW="container.xl" mt={4}>
      <Text>Loading...</Text>
    </Container>
  );
}

if (error) {
  return (
    <Container maxW="container.xl" mt={4}>
      <Alert status="error">
        <AlertIcon />
        {error}
      </Alert>
      <Button onClick={() => navigate('/')} mt={4}>Return to Dashboard</Button>
    </Container>
  );
}

if (!formula) {
  return (
    <Container maxW="container.xl" mt={4}>
      <Alert status="info">
        <AlertIcon />
        No formula found!
      </Alert>
      <Button onClick={() => navigate('/')} mt={4}>Return to Dashboard</Button>
    </Container>
  );
}

  const handlePublicToggle = async () => {
    try {
      const user = auth.currentUser;
      if (!user) {
        console.error("No user logged in");
        return;
      }

      const newIsPublic = !isPublic;
      await updateDoc(doc(db, 'users', user.uid, 'formulas', id), {
        isPublic: newIsPublic
      });
      setIsPublic(newIsPublic);
    } catch (error) {
      console.error("Error updating formula visibility: ", error);
    }
  };


  const calculateTotalCost = () => {
    if (!formula || !formula.ingredients) return 0;
    return formula.ingredients.reduce((total, ingredient) => {
      return total + (parseFloat(ingredient.quantity) * parseFloat(ingredient.costPerGram || 0));
    }, 0);
  };


  if (loading) {
    return <p>Loading...</p>;
  }

  if (!formula) {
    return <p>No formula found!</p>;
  }

  return (
    <Container maxW="container.xl" mt={4}>
  <Button colorScheme="gray" onClick={() => navigate('/')} mb={3}>
    Back to Calculator
  </Button>
  <VStack align="stretch" spacing={4} mb={3}>
    <Box>
      {isEditing ? (
        <>
          <Input
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            mb={2}
            placeholder="Enter formula title"
          />
          <Textarea
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            mb={2}
            placeholder="Enter formula description"
          />
          <NumberInput
            value={batchSize}
            onChange={(valueString) => setBatchSize(valueString)}
            mb={2}
          >
            <NumberInputField placeholder="Enter batch size (g)" />
          </NumberInput>
        </>
      ) : (
        <>
          <Heading color="brand.bodyFont" as="h1">{title}</Heading>
          <Text>{description || "No description available"}</Text>
          <Text>Batch Size: {formula.batchSize}g</Text>
        </>
      )}
      <HStack spacing={4} mt={2}>
        <Button onClick={handleEdit}>Edit Formula</Button>
        <FormControl display="flex" alignItems="center">
          <FormLabel htmlFor="public-private-switch" mb="0">
            {isPublic ? "Public" : "Private"}
          </FormLabel>
          <Switch
            id="public-private-switch"
            isChecked={isPublic}
            onChange={handlePublicToggle}
          />
        </FormControl>
      </HStack>
    </Box>
  </VStack>
  <Text>Batch Size: {formula.batchSize}g</Text>
  <Table variant="simple">
    <Thead>
      <Tr>
        <Th color="brand.tableHeader">Ingredient Name</Th>
        <Th color="brand.tableHeader">Ratio (%)</Th>
        <Th color="brand.tableHeader">Quantity (g)</Th>
        <Th color="brand.tableHeader">Cost per gram ($)</Th>
        <Th color="brand.tableHeader">Total Cost ($)</Th>
      </Tr>
    </Thead>
    <Tbody>
      {formula.ingredients.map((ingredient, idx) => (
        <Tr key={idx}>
          <Td>{ingredient.name}</Td>
          <Td>{ingredient.ratio}%</Td>
          <Td>{ingredient.quantity}g</Td>
          <Td>${ingredient.costPerGram || '0.00'}</Td>
          <Td>${((parseFloat(ingredient.quantity) * parseFloat(ingredient.costPerGram || 0)).toFixed(2))}</Td>
        </Tr>
      ))}
    </Tbody>
    <Tfoot>
      <Tr>
        <Th color="brand.bodyFont" colSpan={4}>Total Cost</Th>
        <Th color="brand.bodyFont">${calculateTotalCost().toFixed(2)}</Th>
      </Tr>
    </Tfoot>
  </Table>
  <Button onClick={() => window.print()} colorScheme="blue" mt={4}>Print</Button>
</Container>
  );
}

export default FormulaPage;
